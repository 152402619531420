import i18next from 'i18n';

export enum SortOptions {
    DateAsc = 'date_asc',
    DateDesc = 'date_desc',
    NameAsc = 'title_asc',
    NameDesc = 'title_desc',
    ClosingSoon = 'closing_soon',
    BestMatch = 'best_match',
    PriceAsc = 'price_asc',
    PriceDesc = 'price_desc',
    Popular = 'popular',

    Winning = 'winning',
    Loosing = 'loosing'
}

export enum FilterOptions {
    Winning = 'winning',
    Loosing = 'loosing'
}

export type DisplaySortOptionsType = { value: SortOptions | undefined, label: string };

export const DisplaySortOptions: DisplaySortOptionsType[] = [
    { value: SortOptions.ClosingSoon, label: i18next.t('header:search-sort.closing-soon-sort') },
    { value: SortOptions.BestMatch, label: i18next.t('header:search-sort.best-match') },
    { value: SortOptions.Popular, label: i18next.t('header:search-sort.popular-sort') },
    { value: SortOptions.PriceAsc, label: i18next.t('header:search-sort.price-descending-sort') },
    { value: SortOptions.PriceDesc, label: i18next.t('header:search-sort.price-ascending-sort') },
    //  { value: SortOptions.DateDesc, label: i18next.t('header:search-sort.date-descending-sort') },
    // { value: SortOptions.DateAsc, label: i18next.t('header:search-sort.date-ascending-sort') },
    { value: SortOptions.NameAsc, label: i18next.t('header:search-sort.name-ascending-sort') },
    { value: SortOptions.NameDesc, label: i18next.t('header:search-sort.name-descending-sort') }
];

export const PersonlizedSortOptions: DisplaySortOptionsType[] = [
    { value: undefined, label: i18next.t('header:search-sort.closing-soon-sort') },
    { value: SortOptions.Winning, label: i18next.t('header:search-sort.winning-sort') },
    { value: SortOptions.Loosing, label: i18next.t('header:search-sort.losing-sort') }
];
