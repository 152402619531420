import { Item, ItemArtist, ItemCategory, ItemCollection, ItemCondition, ItemGenre, ItemPartner, ItemSubCategory } from '@/api';

import React, { useMemo, useState } from 'react';
import { useMeta } from '@/hooks/api/useMeta';
import { MetaAvailableResponse } from '@/queries/search';
import i18next from 'i18n';
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import uniq from 'lodash.uniq';
import AlignCenter from 'public/assets/icons/align-center.svg';
import { motion } from 'framer-motion';
import { useMobile } from '@/hooks/useMobile';
import clsx from 'clsx';
import { DisplaySortOptions, DisplaySortOptionsType } from '@/types/API';
import Button from '../../general/Button';

export type DefaultFilters = { subcategories?: string[], categories?: string[], genres?: string[], artists?: string[], offerTypes?: string[], conditions?: string[], partners?: string[], collections?: string[] };

type ISearchFiltersProps = {
    defaultFilters?: DefaultFilters;
    isDefaultFilterFixed?: boolean;
    artistsFilters: string[];
    offerTypeFilters: string[];
    subCategoriesFilters: string[];
    conditionsFilters: string[];
    partnersFilters: string[];
    collectionsFilters: string[];
    genresFilters: string[];
    categoriesFilters: string[];
    sortBy?: DisplaySortOptionsType | null;
    meta_available: MetaAvailableResponse;
    setArtistsFilters: (filter: string[]) => void;
    setOfferTypeFilters: (filter: string[]) => void;
    setConditionsFilters: (filter: string[]) => void;
    setPartnersFilters: (filter: string[]) => void;
    setCollectionsFilters: (filter: string[]) => void;
    setGenresFilters: (filter: string[]) => void;
    setCategoriesFilters: (filter: string[]) => void;
    setSubCategoriesFilters: (filter: string[]) => void;
    setSortBy: (sort: DisplaySortOptionsType | null) => void;
    loading: boolean;
};

export const entityName = (entity: ((ItemGenre | ItemCategory | ItemSubCategory | ItemArtist | ItemCondition | ItemCollection | ItemPartner) & { available?: number })) => {
    if (entity.available !== undefined) {
        return `${entity.name} (${entity.available})`;
    }
    return entity.name;
};

type FilterEntity = {
    id: string;
    name: string;
    count: number;
    disabled?: boolean;
    options: { value: string, label: string, selected: boolean, disabled?: boolean, onChange: (val: boolean) => void }[]
};

const SearchFilters = (props: ISearchFiltersProps) => {
    const {
        artistsFilters,
        offerTypeFilters,
        conditionsFilters,
        partnersFilters,
        collectionsFilters,
        genresFilters,
        categoriesFilters,
        subCategoriesFilters,
        defaultFilters,
        meta_available: metaAvailable,
        sortBy,
        setArtistsFilters,
        setCategoriesFilters,
        setConditionsFilters,
        setPartnersFilters,
        setCollectionsFilters,
        setGenresFilters,
        setSubCategoriesFilters,
        setOfferTypeFilters,
        setSortBy,
        loading = false,
        isDefaultFilterFixed = false
    } = props;
    const { categories, artists, genres, conditions, partners, subCategories, collections, offer_types: offerTypes } = useMeta(metaAvailable);
    const [open, setOpen] = useState(false);

    const isMobile = useMobile();

    const filters = useMemo(() => {
        const fa: FilterEntity[] = [];
        fa.push({
            id: 'partner',
            count: partnersFilters.length,
            disabled: !(partners && partners.length),
            name: i18next.t('header:search-filters.partner'),
            options: (partners || []).map((partner) => {
                const disabled = defaultFilters?.partners?.includes(partner.name) && isDefaultFilterFixed;
                return {
                    value: partner.name,
                    label: partner.name,
                    disabled,
                    selected: partnersFilters.includes(partner.name),
                    onChange: (checked: boolean) => {
                        if (checked) {
                            setPartnersFilters(uniq([...partnersFilters, partner.name]));
                        } else {
                            setPartnersFilters(partnersFilters.filter((partnerStr: string) => partnerStr !== partner.name));
                        }
                    }
                };
            })
        });

        fa.push({
            id: 'offerTypes',
            count: offerTypeFilters.length,
            disabled: !(offerTypes && offerTypes.length),
            name: i18next.t('header:search-filters.offer-type'),
            options: (offerTypes || []).map((type) => {
                const disabled = defaultFilters?.offerTypes?.includes(type.value) && isDefaultFilterFixed;
                return {
                    value: type.value,
                    label: type.label,
                    disabled,
                    selected: offerTypeFilters.includes(type.value),
                    onChange: (checked: boolean) => {
                        if (checked) {
                            setOfferTypeFilters(uniq([...offerTypeFilters, type.value]));
                        } else {
                            setOfferTypeFilters(offerTypeFilters.filter((name: string) => name !== type.value));
                        }
                    }
                };
            })
        });

        fa.push({
            id: 'artists',
            count: artistsFilters.length,
            disabled: !(artists && artists.length),
            name: i18next.t('header:search-filters.artists'),
            options: (artists || []).map((artist: ItemArtist) => {
                const disabled = defaultFilters?.artists?.includes(artist.slug) && isDefaultFilterFixed;
                return {
                    value: artist.slug,
                    label: artist.name,
                    disabled,
                    selected: artistsFilters.includes(artist.slug),
                    onChange: (checked: boolean) => {
                        if (checked) {
                            setArtistsFilters(uniq([...artistsFilters, artist.slug]));
                        } else {
                            setArtistsFilters(artistsFilters.filter((artistSlug: string) => artistSlug !== artist.slug));
                        }
                    }
                };
            })
        });

        fa.push({
            id: 'condition',
            count: conditionsFilters.length,
            disabled: !(conditions && conditions.length),
            name: i18next.t('header:search-filters.condition'),
            options: (conditions || []).map((condition) => {
                const disabled = defaultFilters?.conditions?.includes(condition.name) && isDefaultFilterFixed;
                return {
                    value: condition.name,
                    label: condition.name,
                    disabled,
                    selected: conditionsFilters.includes(condition.name),
                    onChange: (checked: boolean) => {
                        if (checked) {
                            setConditionsFilters(uniq([...conditionsFilters, condition.name]));
                        } else {
                            setConditionsFilters(conditionsFilters.filter((conditionStr: string) => conditionStr !== condition.name));
                        }
                    }
                };
            })
        });

        fa.push({
            id: 'genres',
            count: genresFilters.length,
            disabled: !(genres && genres.length),
            name: i18next.t('header:search-filters.genres'),
            options: (genres || []).map((genre) => {
                const disabled = defaultFilters?.genres?.includes(genre.slug) && isDefaultFilterFixed;
                return {
                    value: genre.slug,
                    label: genre.name,
                    disabled,
                    selected: genresFilters.includes(genre.slug),
                    onChange: (checked: boolean) => {
                        if (checked) {
                            setGenresFilters(uniq([...genresFilters, genre.slug]));
                        } else {
                            setGenresFilters(genresFilters.filter((genreStr: string) => genreStr !== genre.slug));
                        }
                    }
                };
            })
        });

        fa.push({
            id: 'categories',
            count: categoriesFilters.length,
            disabled: !(categories && categories.length),
            name: i18next.t('header:search-filters.categories'),
            options: (categories || []).map((category) => {
                const disabled = defaultFilters?.categories?.includes(category.slug) && isDefaultFilterFixed;
                return {
                    value: category.slug,
                    label: category.name,
                    disabled,
                    selected: categoriesFilters.includes(category.slug),
                    onChange: (checked: boolean) => {
                        if (checked) {
                            setCategoriesFilters(uniq([...categoriesFilters, category.slug]));
                        } else {
                            setCategoriesFilters(categoriesFilters.filter((catSlug: string) => catSlug !== category.slug));
                        }
                    }
                };
            })
        });

        fa.push({
            id: 'sub-categories',
            count: subCategoriesFilters.length,
            disabled: !(subCategories && subCategories.length),
            name: i18next.t('header:search-filters.sub-categories'),
            options: (subCategories || []).map((subCategory) => {
                const disabled = defaultFilters?.subcategories?.includes(subCategory.slug) && isDefaultFilterFixed;
                return {
                    value: subCategory.slug,
                    label: subCategory.name,
                    disabled,
                    selected: subCategoriesFilters.includes(subCategory.slug),
                    onChange: (checked: boolean) => {
                        if (checked) {
                            setSubCategoriesFilters(uniq([...subCategoriesFilters, subCategory.slug]));
                        } else {
                            setSubCategoriesFilters(subCategoriesFilters.filter((catSlug: string) => catSlug !== subCategory.slug));
                        }
                    }
                };
            })
        });

        fa.push({
            id: 'collections',
            count: collectionsFilters.length,
            disabled: !(collections && collections.length),
            name: i18next.t('header:search-filters.collection'),
            options: (collections || []).map((collection) => {
                const disabled = defaultFilters?.collections?.includes(collection.slug) && isDefaultFilterFixed;
                return {
                    value: collection.slug,
                    label: collection.name,
                    disabled,
                    selected: collectionsFilters.includes(collection.slug),
                    onChange: (checked: boolean) => {
                        if (checked) {
                            setCollectionsFilters(uniq([...collectionsFilters, collection.slug]));
                        } else {
                            setCollectionsFilters(collectionsFilters.filter((collectionStr: string) => collectionStr !== collection.slug));
                        }
                    }
                };
            })
        });

        return fa;
    }, [artists, artistsFilters, categories, categoriesFilters, collections, offerTypes,
        collectionsFilters, conditions, conditionsFilters, defaultFilters?.artists,
        partners, partnersFilters, defaultFilters?.partners, setPartnersFilters,
        defaultFilters?.categories, defaultFilters?.collections, defaultFilters?.conditions,
        defaultFilters?.genres, defaultFilters?.subcategories, defaultFilters?.offerTypes, genres, genresFilters, offerTypeFilters,
        isDefaultFilterFixed, setArtistsFilters, setCategoriesFilters, setCollectionsFilters, setOfferTypeFilters,
        setConditionsFilters, setGenresFilters, setSubCategoriesFilters, subCategories, subCategoriesFilters]);

    const filtersCounter = (artistsFilters.length + genresFilters.length + conditionsFilters.length + partnersFilters.length + categoriesFilters.length + subCategoriesFilters.length + collectionsFilters.length + offerTypeFilters.length);

    return (
        <div>
            {isMobile &&
                <Dialog open={open} onClose={setOpen} className="relative z-40 md:hidden">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
                    />
                    <div className="fixed inset-0 top-[68px] z-40 flex">
                        <DialogPanel
                            transition
                            className="relative ml-auto flex h-full w-full max-w-xs transform flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl transition duration-300 ease-in-out data-[closed]:translate-x-full"
                        >
                            <div className="flex items-center justify-between px-4">
                                <h2 className="text-lg font-medium text-gray-900">{i18next.t('header:search-filters.title')}{filtersCounter > 0 ? ` (${filtersCounter})` : ''}</h2>
                                <button
                                    type="button"
                                    onClick={() => setOpen(false)}
                                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                >
                                    <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                                </button>
                            </div>

                            <form className="mt-4">
                                {filters.map((section, i) => (
                                    <Disclosure key={section.name} as="div" className={clsx('px-4 py-6', { 'border-t border-gray-200': i > 0 })}>
                                        <h3 className="-mx-2 -my-3 flow-root">
                                            <DisclosureButton disabled={section.disabled} className="group flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400 disabled:opacity-40">
                                                <span className="font-medium text-gray-900">{section.name} {section.count > 0 ? ` (${section.count})` : ''}</span>
                                                <span className="ml-6 flex items-center">
                                                    <ChevronDownIcon
                                                        aria-hidden="true"
                                                        className="h-5 w-5 rotate-0 transform group-data-[open]:-rotate-180"
                                                    />
                                                </span>
                                            </DisclosureButton>
                                        </h3>
                                        <DisclosurePanel className="pt-6">
                                            <div className="space-y-6">
                                                {section.options.map((option, optionIdx) => (
                                                    <div key={option.value} className="flex items-center">
                                                        <input
                                                            defaultValue={option.value}
                                                            disabled={option.disabled}
                                                            checked={option.selected}
                                                            onChange={(e) => option.onChange(e.target.checked)}
                                                            id={`filter-${section.id}-${optionIdx}`}
                                                            name={`${section.id}[]`}
                                                            type="checkbox"
                                                            className="h-4 w-4 rounded border-gray-300 text-blue8 focus:ring-blue8 disabled:opacity-40"
                                                        />
                                                        <label
                                                            htmlFor={`filter-${section.id}-${optionIdx}`}
                                                            className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-blue15"
                                                        >
                                                            {option.label}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </DisclosurePanel>
                                    </Disclosure>
                                ))}
                            </form>
                        </DialogPanel>
                    </div>
                </Dialog>
            }
            <div className="mx-auto text-center">
                <section aria-labelledby="filter-heading" className="py-6">
                    <div className="flex items-center justify-between [&>*]:flex-1">
                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <MenuButton className="group inline-flex justify-center text-sm font-medium text-blue15 hover:text-blue12">
                                    {i18next.t('header:search-sort.sort')}: <span className="ml-2">{sortBy ? sortBy.label : <span className="text-gray-400">{i18next.t('header:search-sort.select')}</span>}</span>
                                    <ChevronDownIcon
                                        aria-hidden="true"
                                        className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-blue15 group-hover:text-blue12"
                                    />
                                </MenuButton>
                            </div>
                            <MenuItems
                                transition
                                className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <div className="py-1">
                                    {DisplaySortOptions.map((option) => (
                                        <MenuItem key={option.value}>
                                            <button
                                                onClick={(e) => {
                                                    setSortBy(option);
                                                }}
                                                className="block w-full text-left px-4 py-2 text-sm font-medium text-blue15 data-[focus]:bg-[#f2f2f2]"
                                            >
                                                {option.label}
                                            </button>
                                        </MenuItem>
                                    ))}
                                </div>
                            </MenuItems>
                        </Menu>
                        <div className="gap-2 hidden md:flex justify-center [&>*]:whitespace-nowrap">
                            {Object.values(Item.OfferTypeEnum).map((value) => {
                                const disabled = (defaultFilters?.offerTypes?.includes(value as string) && isDefaultFilterFixed) || !offerTypes.find((type) => type.value === value);
                                const selected = offerTypeFilters.includes(value as string);
                                return (
                                    <Button
                                        key={value}
                                        size="sm"
                                        className="!py-[6px]"
                                        disabled={disabled && !selected}
                                        light={!selected}
                                        onClick={() => {
                                            if (!selected) {
                                                setOfferTypeFilters(uniq([...offerTypeFilters, value as string]));
                                            } else {
                                                setOfferTypeFilters(offerTypeFilters.filter((name: string) => name !== value));
                                            }
                                        }}>
                                        {i18next.t(`offer-types.${value}` as 'offer-types.auction')}
                                    </Button>
                                );
                            })}
                        </div>
                        <div className="">
                            <button
                                className="flex ml-auto gap-2 items-center justify-center text-blue15 text-sm font-[500] border-[1px] border-grey13 px-[12px] py-[6px] rounded-[60px] hover:bg-grey12"
                                onClick={() => setOpen(!open)}>
                                <span className="font-[500]"> <AlignCenter /></span> {i18next.t('header:search-filters.title')}{filtersCounter > 0 ? ` (${filtersCounter})` : ''}
                            </button>
                        </div>
                    </div>
                    <div>
                        <PopoverGroup className="hidden md:flex md:space-x-8 justify-end flex-wrap">
                            {filters.map((section) => (
                                <Popover
                                    key={section.name}
                                    id={`desktop-menu-${section.id}`}
                                    className="relative inline-block text-left"
                                >
                                    <motion.div animate={{ height: open ? 'fit-content' : 0 }} className="overflow-hidden h-0">
                                        <PopoverButton disabled={section.disabled} className="group inline-flex items-center justify-center text-sm font-medium text-blue15 hover:enabled:text-blue12 disabled:opacity-40 disabled:cursor-not-allowed mt-4">
                                            <span>{section.name}</span>
                                            {section.count > 0 ? (
                                                <span className="ml-1.5 rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-700">
                                                    {section.count}
                                                </span>
                                            ) : null}
                                            <ChevronDownIcon
                                                aria-hidden="true"
                                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-blue15 group-hover:enabled:text-blue12"
                                            />
                                        </PopoverButton>
                                    </motion.div>
                                    <PopoverPanel
                                        transition
                                        className="absolute right-0 z-10 mt-2 origin-top-right rounded bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                    >
                                        <form className="space-y-4">
                                            {section.options.map((option, optionIdx) => (
                                                <div key={option.value} className="flex items-center">
                                                    <input
                                                        defaultValue={option.value}
                                                        disabled={option.disabled}
                                                        checked={option.selected}
                                                        onChange={(e) => option.onChange(e.target.checked)}
                                                        id={`filter-${section.id}-${optionIdx}`}
                                                        name={`${section.id}[]`}
                                                        type="checkbox"
                                                        className="h-4 w-4 rounded border-gray-300 text-blue8 focus:ring-blue8 disabled:opacity-40 disabled:cursor-not-allowed"
                                                    />
                                                    <label
                                                        htmlFor={`filter-${section.id}-${optionIdx}`}
                                                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-blue15"
                                                    >
                                                        {option.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </form>
                                    </PopoverPanel>
                                </Popover>
                            ))}
                        </PopoverGroup>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SearchFilters;
