import { PaginationResponseDto } from '@/api';
import clsx from 'clsx';
import i18next from 'i18n';
import isNil from 'lodash.isnil';
import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import Chevron from 'public/assets/icons/chevron-right-long.svg';

type IPaginationProps = {
    transparent?: boolean
    data: PaginationResponseDto | undefined
    offsetChanged: (offset: number) => void;
    itemsPerPageChanged: (itemsPerPage: number) => void;
    defaultItemsPerPage: number;
    factor?: 4 | 10
};

const Pagination = (props: IPaginationProps) => {
    const { data, offsetChanged: pageChanged, itemsPerPageChanged, defaultItemsPerPage, factor = 10 } = props;

    const [itemsPerPageOptions, setItemsPerPageOptions] = useState(factor === 4 ? [
        { id: 8, value: 8, label: i18next.t('pagination.items-per-page', { count: 8 }) as string },
        { id: 16, value: 16, label: i18next.t('pagination.items-per-page', { count: 16 }) },
        { id: 24, value: 24, label: i18next.t('pagination.items-per-page', { count: 24 }) },
        { id: 48, value: 48, label: i18next.t('pagination.items-per-page', { count: 48 }) },
        { id: 96, value: 96, label: i18next.t('pagination.items-per-page', { count: 96 }) }
    ] : [
        { id: 5, value: 5, label: i18next.t('pagination.items-per-page', { count: 5 }) as string },
        { id: 10, value: 10, label: i18next.t('pagination.items-per-page', { count: 10 }) },
        { id: 20, value: 20, label: i18next.t('pagination.items-per-page', { count: 20 }) },
        { id: 40, value: 40, label: i18next.t('pagination.items-per-page', { count: 40 }) },
        { id: 80, value: 80, label: i18next.t('pagination.items-per-page', { count: 80 }) }
    ]);

    const defaultItemsPerPageCount = defaultItemsPerPage || (factor === 4 ? 16 : 20);

    useEffect(() => {
        if (defaultItemsPerPage && defaultItemsPerPageCount) {
            const arrOfOptions = itemsPerPageOptions.filter((option) => {
                if (defaultItemsPerPage && defaultItemsPerPageCount === option.id) {
                    return false;
                }
                return true;
            });
            arrOfOptions.unshift({ id: defaultItemsPerPageCount, value: defaultItemsPerPageCount, label: `${defaultItemsPerPageCount}` });
            setItemsPerPageOptions(arrOfOptions.sort((a, b) => a.value - b.value));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultItemsPerPage, defaultItemsPerPageCount]);

    const [itemsPerPage, setItemsPerPage] = useState<{ id: number; value: number; label: string }>({ id: defaultItemsPerPageCount, value: defaultItemsPerPageCount, label: `${defaultItemsPerPageCount}` });

    useEffect(() => {
        setItemsPerPage({ id: defaultItemsPerPage, value: defaultItemsPerPage, label: `${defaultItemsPerPage}` });
    }, [defaultItemsPerPage]);

    const startItem = data ? (data.offset) + 1 : undefined;
    const endItem = data ? Math.min((startItem || 0) + (data.limit) - 1, data.total) : undefined;
    const totalPages = data ? Math.ceil((data.total) / (data.limit)) : undefined;
    const currentPage = data ? Math.ceil(((data.offset) + (data.limit)) / (data.limit)) : undefined;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const pagesToShow = useMemo(() => {
        if (isNil(currentPage) || isNil(totalPages)) {
            return [];
        }
        const priorityPages = [1, currentPage - 1, currentPage, currentPage + 1, totalPages];
        const totalPossiblePages = [1, currentPage - 4, currentPage - 3, currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, currentPage + 3, currentPage + 4, totalPages];

        // eslint-disable-next-line @typescript-eslint/no-shadow
        const pagesToShow = totalPossiblePages.filter(
            (page, index, self) => page > 0 && page <= totalPages && self.indexOf(page) === index
        );
        pagesToShow.sort((a, b) => a - b);
        while (pagesToShow.length > 5) {
            if (currentPage < 3) {
                const firstNonPriroty = pagesToShow.findLastIndex((p) => !priorityPages.includes(p));
                pagesToShow.splice(firstNonPriroty, 1);
            } else {
                const firstNonPriroty = pagesToShow.findIndex((p) => !priorityPages.includes(p));
                pagesToShow.splice(firstNonPriroty, 1);
            }
        }

        if (pagesToShow.length === 5) {
            pagesToShow.splice(pagesToShow.length - 1, 0, -1);
        }

        return pagesToShow;
    }, [currentPage, totalPages]);

    if (isNil(currentPage) || isNil(totalPages) || isNil(startItem) || isNil(endItem) || isNil(data) || data.total <= 1) {
        return null;
    }

    if (pagesToShow.length <= 1 && data.total <= itemsPerPageOptions[0].value) {
        return null;
    }

    const itemsCounter = <div>{data.total} {i18next.t('common:pagination.items')}</div>;
    const itemsPerPageSelctor = (
        <div className="flex flex-row items-center">
            <span className="mx-[12px]">{i18next.t('common:pagination.display')}</span>
            <Select
                className="cursor-pointer"
                styles={{
                    control: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        borderRadius: 50,
                        borderColor: '#cccccc',
                        '&:hover': {
                            borderColor: '#cccccc',
                        },
                        boxShadow: 'none',
                    }),
                    option: (styles, { isFocused, isSelected }) => ({
                        ...styles,
                        background: isFocused
                            ? '#001f308f'
                            : isSelected
                                ? '#2D5271'
                                : undefined,
                        zIndex: 1
                    }),
                    menuList: (baseStyles) => ({
                        ...baseStyles,
                        padding: 0,
                    }),
                    menu: (baseStyles) => ({
                        ...baseStyles,
                        zIndex: 100
                    }),
                }}
                defaultValue={itemsPerPage}
                isDisabled={false}
                isLoading={false}
                isClearable={false}
                isSearchable={false}
                name="color"
                options={itemsPerPageOptions}
                onChange={(selected) => {
                    if (selected) {
                        setItemsPerPage(selected);
                        itemsPerPageChanged(selected.value);
                        pageChanged(0);
                    }
                }}
            />
        </div>
    );

    return (
        <div className="flex flex-col md:flex-row justify-between text-[14px] text-grey15 font-[500] py-[16px] px-[16px] md:px-0">

            <div className="hidden md:block w-[146px]">{itemsCounter}</div>
            <div className="flex flex-row items-center mx-auto">
                <button
                    onClick={() => pageChanged((currentPage - 2) * data.limit)}
                    disabled={currentPage === 1}
                    className=" text-gray-700 flex  justify-center items-center mr-[8px] hover:bg-gray-50 disabled:text-blue5 disabled:opacity-60 disabled:!cursor-not-allowed [&_*]:disabled:!cursor-not-allowed w-[32px] h-[32px] rotate-180"
                >
                    <Chevron />
                </button>
                <div>{pagesToShow.map((page) => {
                    if (page === -1) {
                        return <span key={page} className="text-[14px] font-[500] text-blue12 w-[32px] h-[32px] rounded-full inline-block text-center cursor-default">...</span>;
                    }
                    return (
                        <button
                            key={page}
                            onClick={() => pageChanged((page - 1) * data.limit)}
                            aria-current="page"
                            className={clsx(
                                'text-[14px] font-[500] text-blue12 w-[32px] h-[32px] rounded-full hover:bg-grey mx-[8px]',
                                page === currentPage && 'bg-main !text-white hover:!text-white'
                            )}
                        >
                            {page}
                        </button>
                    );
                })}
                </div>
                <button
                    onClick={() => pageChanged((currentPage) * data.limit)}
                    disabled={currentPage === totalPages}
                    className="text-gray-700 flex justify-center items-center ml-[8px] hover:bg-gray-50 disabled:text-blue5 disabled:opacity-60 disabled:!cursor-not-allowed [&_*]:disabled:!cursor-not-allowed w-[32px] h-[32px]"
                >
                    <Chevron />
                </button>
            </div>
            <div className="hidden md:block">{itemsPerPageSelctor}</div>
            <div className="md:hidden flex justify-between items-center flex-row mt-[16px]">
                <div>{itemsCounter}</div>
                <div>{itemsPerPageSelctor}</div>
            </div>
        </div>
    );
};

export default Pagination;
