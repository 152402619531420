import { Item, ItemArtist, ItemCategory, ItemCollection, ItemCondition, ItemGenre, ItemPartner, ItemSubCategory } from '@/api';
import { getAllCollectionsQueryFn } from '@/queries/collection';
import { getAllCategoriesQueryFn, getAllArtistQueryFn, getAllConditionsQueryFn, getAllGenresQueryFn, getAllPartnersQueryFn } from '@/queries/meta';
import { MetaAvailableResponse } from '@/queries/search';
import { useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import { useCallback, useMemo } from 'react';
import sortBy from 'lodash.sortby';

export const useMeta = (availableMeta?: MetaAvailableResponse) => {
    const { data: categories, isFetching: isFetchingCategories } = useQuery({
        queryKey: ['getAllCategoriesQueryFn'],
        queryFn: getAllCategoriesQueryFn,
        staleTime: 10000
    });

    const { data: artists, isFetching: isFetchingArtists } = useQuery({
        queryKey: ['getAllArtistQueryFn'],
        queryFn: getAllArtistQueryFn,
        staleTime: 10000
    });

    const { data: conditions, isFetching: isFetchingConditions } = useQuery({
        queryKey: ['getAllConditionsQueryFn'],
        queryFn: getAllConditionsQueryFn,
        staleTime: 10000
    });

    const { data: partners, isFetching: isFetchingPartnerss } = useQuery({
        queryKey: ['getAllPartnersQueryFn'],
        queryFn: getAllPartnersQueryFn,
        staleTime: 10000
    });

    const { data: genres, isFetching: isFetchingGenres } = useQuery({
        queryKey: ['getAllGenresQueryFn'],
        queryFn: getAllGenresQueryFn,
        staleTime: 10000
    });

    const { data: collections, isFetching: isFetchingCollections } = useQuery({
        queryKey: ['getAllCollectionsQueryFn'],
        queryFn: getAllCollectionsQueryFn,
        staleTime: 10000
    });

    const subCategories = useMemo(() => {
        return categories?.reduce((prev, category) => {
            return [...prev, ...category.sub_categories];
        }, [] as Array<ItemSubCategory>);
    }, [categories]);

    const findAvailable = useCallback((type: keyof MetaAvailableResponse, id: string) => {
        if (!availableMeta) {
            return undefined;
        }
        return availableMeta[type].find((a) => a.id === id)?.available || -1;
    }, [availableMeta]);

    const actualCategories = useMemo(() => {
        return sortBy(categories?.map((e) => {
            return { ...e, available: findAvailable('category', e.id) } as ItemCategory & { available?: number };
        }).filter((e) => e.available !== -1), 'name');
    }, [categories, findAvailable]);

    const actualGenres = useMemo(() => {
        return sortBy(genres?.map((e) => {
            return { ...e, available: findAvailable('genres', e.id) } as ItemGenre & { available?: number };
        }).filter((e) => e.available !== -1), 'name');
    }, [genres, findAvailable]);

    const actualConditions = useMemo(() => {
        return sortBy(conditions?.map((e) => {
            return { ...e, available: findAvailable('conditions', e.id) } as ItemCondition & { available?: number };
        }).filter((e) => e.available !== -1), 'name');
    }, [conditions, findAvailable]);

    const actualPartnerss = useMemo(() => {
        return sortBy(partners?.map((e) => {
            return { ...e, available: findAvailable('partners', e.id) } as ItemPartner & { available?: number };
        }).filter((e) => e.available !== -1), 'name');
    }, [partners, findAvailable]);

    const actualArtists = useMemo(() => {
        return sortBy(artists?.map((e) => {
            return { ...e, available: findAvailable('artists', e.id) } as ItemArtist & { available?: number };
        }).filter((e) => e.available !== -1), 'name');
    }, [artists, findAvailable]);

    const actualSubCategories = useMemo(() => {
        return sortBy(subCategories?.map((e) => {
            return { ...e, available: findAvailable('sub_category', e.id) } as ItemSubCategory & { available?: number };
        }).filter((e) => e.available !== -1), 'name');
    }, [subCategories, findAvailable]);

    const actualCollections = useMemo(() => {
        return sortBy(collections?.map((e) => {
            return { ...e, available: findAvailable('collections', e.id) } as ItemCollection & { available?: number };
        }).filter((e) => e.available !== -1), 'name');
    }, [collections, findAvailable]);

    const actualOfferTypes = useMemo(() => {
        return Object.values(Item.OfferTypeEnum)?.map((e) => {
            return { value: e, available: findAvailable('offer_types', e as string), label: i18next.t(`offer-types.${e}` as 'offer-types.auction') as string } as { value: string, label: string } & { available?: number };
        }).filter((e) => e.available !== -1);
    }, [findAvailable]);

    return {
        categories: actualCategories,
        artists: actualArtists,
        conditions: actualConditions,
        partners: actualPartnerss,
        genres: actualGenres,
        subCategories: actualSubCategories,
        collections: actualCollections,
        offer_types: actualOfferTypes,
        isFetching: isFetchingArtists || isFetchingCategories || isFetchingConditions || isFetchingPartnerss || isFetchingGenres || isFetchingCollections
    };
};
