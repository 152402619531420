import { CollectionApi } from '@/api';
import { apiConfiguration } from '@/api/config';

export const collectionApi = new CollectionApi(apiConfiguration);

export const getAllCollectionsQueryFn = async () => {
    return collectionApi.collectionControllerGetAllCollections();
};

export const getFeaturedCollectionsQueryFn = async () => {
    return collectionApi.collectionControllerGetFeaturedCollections();
};

export const getCollectionQueryFn = async ({ meta: { collection } }: { meta: { collection: string } }) => {
    return collectionApi.collectionControllerGetCollection(collection);
};
